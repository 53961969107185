import { t } from "@lingui/macro"
import { ReactComponentRegistry } from "../../../cb/components/ReactRegistry"
import { roomDossierContext } from "../../../cb/interfaces/context"
import { ChatSettings } from "../../chatSettings"
import { isChatursafeActive } from "../../featureFlagUtil"
import { C2CNotificationLimit, convertC2CNotificationLimit, UserListSortOptions } from "../../roomDossier"
import { showToast } from "../../showToast"
import { i18n } from "../../translation"
import { BannedTermsModal } from "./bannedTermsModal"
import { BansAndSilencesModal } from "./bansAndSilencesModal"
import { FanClubModal } from "./fanClubModal"
import { ModeratorsModal } from "./moderatorsModal"
import { RegionBlockModal } from "./regionBlockModal"
import type { ISettingsModal } from "./baseSettingsModal"
import type { IChoices } from "../../../cb/ui/fields"
import type { IRoomContext } from "../../context"
import type { IUserChatSettings } from "../../roomDossier"

export class BroadcastChatSettings extends ChatSettings {
    private chatAllowedByLabel: HTMLLabelElement
    private sortUsersSelect: HTMLSelectElement
    private chatAllowedBySelect: HTMLSelectElement
    private moderatorsExpireAfterSelect: HTMLSelectElement
    private silenceOtherBroadcastersSelect: HTMLSelectElement
    private c2cNotificationLimitSelect: HTMLSelectElement
    private bansAndSilencesModal: BansAndSilencesModal
    private moderatorsModal: ModeratorsModal
    private fanClubModal: FanClubModal
    private regionBlockModal: RegionBlockModal
    private bannedTermsModal: BannedTermsModal

    constructor() {
        super()
    }

    protected initData(): void {
        super.initData()
        
        this.regionBlockModal = new RegionBlockModal()
        this.bansAndSilencesModal = new BansAndSilencesModal()
        this.moderatorsModal = new ModeratorsModal()
        this.fanClubModal = new FanClubModal()
        this.bannedTermsModal = new BannedTermsModal()
    }
    
    protected initUI(): void {
        super.initUI()
        this.createChatursafeFilterSettings()
    }
    
    protected createChatursafeFilterSettings(): void {
        if (!isChatursafeActive()) {
            return
        }
        const chatursafeFilterToggleDiv = document.createElement("div")
        const ChatursafeFilterSetting = ReactComponentRegistry.get("ChatursafeFilterSetting")
        const initialSettings = roomDossierContext.getState().chatursafeFilterSettings

        const ToastContainer = ReactComponentRegistry.get("SharedToastContainer")
        if (ToastContainer !== undefined) {
            const wrapper = document.createElement("div")
            wrapper.id = "chatursafe-filter-toast-container"
            new ToastContainer({ containerId: "chatursafe-filter-toast-container", style: { position: "absolute" }  }, wrapper)
            this.element.appendChild(wrapper)
        }

        if (!initialSettings) {
            error("Chatursafe filter settings not found in dossier.")
        }
        
        new ChatursafeFilterSetting({ 
            filterSettings: initialSettings,
            saveCallback: () => {
                showToast({
                    message: t`Filter preference updated`,
                    type: "chatursafe-filter-updated",
                })
            },
        }, chatursafeFilterToggleDiv)
        
        this.form.prepend(chatursafeFilterToggleDiv)
        this.getStyles().styleHeader(this.fontSettingsHeader, t`Font Settings`)
    }

    protected createUserSettings(): void {
        this.usersSettings.appendChild(this.createSortUsers())
        this.usersSettings.appendChild(this.createHighestTokenColorPicker())
        this.usersSettings.appendChild(this.createChatAllowedBy())
        this.usersSettings.appendChild(this.createModeratorsExpireAfter())
        this.usersSettings.appendChild(this.createSilenceOtherBroadcasters())
    }

    protected createNotificationSettings(): void {
        super.createNotificationSettings()
        this.notificationsSettings.appendChild(this.createC2CNotificationLimit())
    }

    protected createOtherSettings(): void {
        this.otherSettings.appendChild(this.createTipVolumePicker())
        this.otherSettings.appendChild(this.createCollapseNoticesPicker())
        this.otherSettings.appendChild(this.createModalLink(i18n.viewEditBansAndSilences, this.bansAndSilencesModal, "view-edit-bans-and-silences"))
        this.otherSettings.appendChild(this.createModalLink(i18n.viewEditModerators, this.moderatorsModal, "view-edit-moderators"))
        this.otherSettings.appendChild(this.createModalLink(i18n.viewFanClubMembers, this.fanClubModal, "view-fan-club-members"))
        this.otherSettings.appendChild(this.createModalLink(i18n.viewEditRegionBlockExemptions, this.regionBlockModal, "view-edit-region-block-exemptions"))
        this.otherSettings.appendChild(this.createManageIgnoredUsersPicker())
        this.otherSettings.appendChild(this.createModalLink(i18n.viewEditBannedTerms, this.bannedTermsModal, "view-edit-banned-terms"))
    }

    private createSortUsers(): HTMLElement {
        const label = document.createElement("label")
        this.sortUsersSelect = document.createElement("select")
        this.sortUsersSelect.dataset.testid = "sort-users-select"
        return this.createRow(
            label,
            i18n.sortUsers,
            this.sortUsersSelect,
            this.getSortUsersOptions(),
        )
    }

    private createChatAllowedBy(): HTMLElement {
        this.chatAllowedByLabel = document.createElement("label")
        this.chatAllowedBySelect = document.createElement("select")
        this.chatAllowedBySelect.dataset.testid = "chat-allowed-by-select"
        return this.createRow(
            this.chatAllowedByLabel,
            i18n.chatAllowedBy,
            this.chatAllowedBySelect,
            this.getChatAllowedByOptions(),
        )
    }

    private createModeratorsExpireAfter(): HTMLElement {
        const label = document.createElement("label")
        this.moderatorsExpireAfterSelect = document.createElement("select")
        this.moderatorsExpireAfterSelect.dataset.testid = "moderators-expire-after-select"
        return this.createRow(
            label,
            i18n.moderatorsExpireAfter,
            this.moderatorsExpireAfterSelect,
            this.getModeratorsExpireAfterOptions(),
        )
    }

    private createSilenceOtherBroadcasters(): HTMLElement {
        const label = document.createElement("label")
        this.silenceOtherBroadcastersSelect = document.createElement("select")
        this.silenceOtherBroadcastersSelect.dataset.testid = "silence-other-broadcasters-select"
        return this.createRow(
            label,
            i18n.silenceOtherBroadcasters,
            this.silenceOtherBroadcastersSelect,
            this.getSilenceOtherBroadcastersOptions(),
        )
    }

    private createC2CNotificationLimit(): HTMLElement {
        const label = document.createElement("label")
        this.c2cNotificationLimitSelect = document.createElement("select")
        this.c2cNotificationLimitSelect.dataset.testid = "notify-rate-limit-select"
        return this.createRow(
            label,
            i18n.preventC2CRedisplay,
            this.c2cNotificationLimitSelect,
            this.getC2CNotificationLimitOptions(),
        )
    }

    private getSortUsersOptions(): IChoices[] {
        return [
            { label: i18n.alphabetically, value: UserListSortOptions.Alphabetical },
            { label: i18n.tokensCC, value: UserListSortOptions.Tokens },
        ]
    }

    private getChatAllowedByOptions(): IChoices[] {
        return [
            { label: i18n.allUsers, value: "all" },
            { label: i18n.usersWhoTippedToday, value: "tip_recent" },
            { label: i18n.usersWhoHaveTipped, value: "tip_anytime" },
            { label: i18n.usersWhoHaveTokens, value: "tokens" },
        ]
    }

    private getModeratorsExpireAfterOptions(): IChoices[] {
        return [
            { label: i18n.oneDay, value: "1" },
            { label: i18n.twoDays, value: "2" },
            { label: i18n.oneWeek, value: "7" },
            { label: i18n.twoWeeks, value: "14" },
            { label: i18n.oneMonth, value: "30" },
            { label: i18n.twoMonths, value: "60" },
            { label: i18n.sixMonths, value: "180" },
        ]
    }

    private getSilenceOtherBroadcastersOptions(): IChoices[] {
        return [
            { label: i18n.no, value: "false" },
            { label: i18n.yesIfBroadcasting, value: "live" },
            { label: i18n.yesAlways, value: "true" },
        ]
    }

    private getC2CNotificationLimitOptions(): IChoices[] {
        return [
            { label: i18n.noLimit, value: C2CNotificationLimit.None },
            { label: i18n.fiveMinutes, value: C2CNotificationLimit.FiveMinutes },
            { label: i18n.forever, value: C2CNotificationLimit.Forever },
        ]
    }

    protected getSaveEndpoint(): string {
        return "api/broadcasterchatsettings/"
    }

    protected getSaveColorEndpoint(): string {
        return "choose_broadcaster_chat_color/"
    }

    protected hasModeratorPrivileges(): boolean {
        return true
    }

    protected isBroadcaster(): boolean {
        return true
    }

    protected getFormData(): Record<string, string> {
        const formData = super.getFormData()
        formData["sort_users"] =  this.sortUsersSelect.value
        formData["allowed_chat"] =  this.chatAllowedBySelect.value
        formData["mod_expire"] =  this.moderatorsExpireAfterSelect.value
        formData["silence_broadcasters"] =  this.silenceOtherBroadcastersSelect.value
        formData["c2c_notify"] = this.c2cNotificationLimitSelect.value
        return formData
    }

    protected updateChatSettings(chatSettings: IUserChatSettings): void {
        super.updateChatSettings(chatSettings)
        this.sortUsersSelect.value = chatSettings.sortUsersKey
        this.chatAllowedBySelect.value = chatSettings.allowedChat
        this.moderatorsExpireAfterSelect.value = chatSettings.modExpiration.toString(10)
        this.silenceOtherBroadcastersSelect.value = chatSettings.silenceBroadcasters
        this.c2cNotificationLimitSelect.value = chatSettings.c2cNotificationLimit.toString(10)
    }

    protected setUserChatSettings(): void {
        super.setUserChatSettings()
        this.userChatSettings.sortUsersKey = this.sortUsersSelect.value as UserListSortOptions
        this.userChatSettings.allowedChat = this.chatAllowedBySelect.value
        this.userChatSettings.modExpiration = parseInt(this.moderatorsExpireAfterSelect.value)
        this.userChatSettings.silenceBroadcasters = this.silenceOtherBroadcastersSelect.value
        this.userChatSettings.c2cNotificationLimit = convertC2CNotificationLimit(parseInt(this.c2cNotificationLimitSelect.value))
    }

    protected handleRoomLoaded(context: IRoomContext): void {
        super.handleRoomLoaded(context)
        if (context.dossier.isAgeVerified === false) {
            this.chatAllowedByLabel.innerText = `${i18n.chatAllowedBy} (${i18n.ageVerificationRequired})`
            this.chatAllowedByLabel.style.color = "#888"
            this.chatAllowedBySelect.title = `${i18n.onlyAgeVerifiedCanChangeSetting}.`
            this.chatAllowedBySelect.disabled = true
            this.chatAllowedBySelect.onclick = (event) => {
                event.preventDefault()
                this.chatAllowedBySelect.blur()
            }
        }
    }

    private createModalLink(text: string, modal: ISettingsModal, testid?: string): HTMLDivElement {
        const label = document.createElement("label")
        const link = this.createSettingsLink(label, text)
        link.appendChild(label)

        label.onclick = () => {
            modal.reload()
            modal.show()
        }
        if(testid !== undefined) {label.dataset.testid = testid}
        return link
    }
}
