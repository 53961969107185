import { colorClass } from "../../../cb/colorClasses"
import { Pagination } from "../../../cb/components/pagination"
import { dom } from "../../tsxrender/dom"

interface ISettingsTableProps<RowProps> {
    title: string,
    info: string,
    onPageRequest: (page: number) => void,
    rowHeaders: (string | HTMLElement)[],
    rowGenerators: ((props: RowProps) => HTMLTableCellElement)[],
    minHeight: string,
}

export class SettingsTable<RowProps> {
    public element: HTMLDivElement
    private table = this.createTable()
    private pagination = this.createPagination()

    constructor(private props: ISettingsTableProps<RowProps>) {
        if (this.props.rowHeaders.length !== this.props.rowGenerators.length) {
            error("Settings table row header count does not match row generator count")
            return
        }

        this.table.appendChild(this.createTableHeader())

        this.element = <div colorClass={colorClass.textColor}>
            { this.createTableTitle() }
            { this.createTableInfo() }
            { this.createTableContainer() }
            { this.pagination.element }
        </div>
    }

    public reset(): void {
        while (this.table.firstChild !== null) {
            this.table.removeChild(this.table.firstChild)
        }
        this.table.appendChild(this.createTableHeader())
    }

    public addRow(rowProps: RowProps): void {
        this.table.appendChild(this.createRow(rowProps))
    }

    public updatePagination(pageCount: number, currentPage: number): void {
        if (pageCount <= 1) {
            this.pagination.element.style.display = "none"
        } else if (!isNaN(pageCount)) {
            this.pagination.element.style.display = ""
            this.pagination.updatePageInfo(pageCount, currentPage)
        }
    }

    private createTableTitle(): HTMLDivElement {
        const styles: CSSX.Properties = {
            fontSize: "1.4em",
            fontFamily: "'UbuntuMedium', Arial, Helvetica, sans-serif",
            fontWeight: "normal",
            textAlign: "center",
            marginTop: "20px",

        }
        return <div colorClass="bluetxt" style={styles} data-testid="title">
            { this.props.title }
        </div>
    }

    private createTableInfo(): HTMLParagraphElement {
        return <p style={{ textAlign: "center" }} data-testid="info">
            { this.props.info }
        </p>
    }

    private createTableContainer(): HTMLDivElement {
        const styles: CSSX.Properties = {
            width: "100%",
            minHeight: this.props.minHeight,
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
            boxSizing: "border-box",
        }
        return <div colorClass="table" style={styles}>
            { this.table }
        </div>
    }

    private createTable(): HTMLTableElement {
        const styles: CSSX.Properties = {
            width: "100%",
            borderCollapse: "collapse",
        }
        return <table style={styles}></table>
    }

    private createTableHeader(): HTMLDivElement {
        const styles: CSSX.Properties = {
            borderBottomWidth: "2px",
            borderBottomStyle: "solid",
        }
        return <thead style={styles}>
            <tr>
                { this.props.rowHeaders.map(header => this.createTableHeaderCell(header)) }
            </tr>
        </thead>
    }

    private createTableHeaderCell(content: string | HTMLElement): HTMLTableCellElement {
        const styles: CSSX.Properties = {
            textAlign: "center",
            fontSize: "1.2em",
            padding: "3px",
        }
        return <th style={styles} data-testid="header">
            { content }
        </th>
    }

    private createRow(rowProps: RowProps): HTMLElement {
        const styles: CSSX.Properties = {
            height: "22px",
            verticalAlign: "middle",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
        }
        return <tr colorClass="row" style={styles}>
            { this.props.rowGenerators.map(rowGenerator => rowGenerator(rowProps)) }
        </tr>
    }

    private createPagination(): Pagination {
        return new Pagination({
            requestNewPage: (page) => {
                this.props.onPageRequest(page)
            },
        })
    }

}
